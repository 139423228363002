.contactInfo{
    text-align: center;
    font-size: large;
    border-radius: 5px;
    background-color: #DCDCDC;
    color:#E59026;
    margin-bottom: 2rem !important;
}

.contactIcons{
    margin: auto !important;
    justify-content: center !important;
    display: block;
    width: 8rem;
    height: 8rem;
}
.contactText{
    text-align: center !important;
    font-size: medium;
}